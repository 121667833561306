import { Mail, Notifications, Pets, Usb } from '@mui/icons-material';
import { AppBar, Avatar, Badge, Box, InputBase, Menu, MenuItem, styled, Toolbar, Typography } from '@mui/material';
import React, { useState } from 'react';
import MenuIcon from '@mui/icons-material/Menu';

const links = [
  { page: 'Home', href: '/home' },
  { page: 'Services', href: '/home#services' },
  { page: 'About Us', href: '/home#about' },
  { page: 'Login', href: '/login' },
  { page: 'Sign Up', href: '/signup' },
  { page: 'Get Started', href: '/signup' },
];

const StyledToolbar = styled(Toolbar)({
  display: 'flex',
  justifyContent: 'space-between',
  backgroundColor: '#435170',
  boxShadow: 'none',
});

const Links = styled(Box)(({ theme }) => ({
  display: 'none',
  alignItems: 'center',
  gap: '20px',
  [theme.breakpoints.up('sm')]: {
    display: 'flex',
  },
}));

const MenuBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  [theme.breakpoints.up('sm')]: {
    display: 'none',
  },
}));
const Navbar = () => {
  const [open, setOpen] = useState(false);
  return (
    <AppBar position="sticky" sx={{ boxShadow: 'none' }}>
      <StyledToolbar>
        <Typography
          variant="h4"
          component="h1"
          sx={{
            padding: '10px 0',
            textTransform: 'uppercase',
            fontFamily: 'Poppins, sans-serif',
            letterSpacing: '3px',
            lineHeight: '1',
            fontWeight: '750',
            display: { xs: 'none', sm: 'block' },
          }}
        >
          BuildMyStock
          <Typography
            variant="h4"
            component="span"
            sx={{
              textTransform: 'uppercase',
              fontFamily: 'Poppins, sans-serif',
              letterSpacing: '3px',
              lineHeight: '1',
              fontWeight: '750',
              color: '#fdc134',
            }}
          >
            .COM
          </Typography>
        </Typography>
        <Pets sx={{ display: { xs: 'block', sm: 'none' } }} />
        <Links>
          {links.map((link) => (
            // eslint-disable-next-line react/jsx-key
            <Typography
              variant="h6"
              noWrap
              component="a"
              href={link.href}
              sx={{
                color: '#adc7de',
                fontWeight: 'normal !important',
                fontSize: '17px !important',
                margin: '0 10px',
                "&:hover":{
                    color:"#fff"
                },
              }}
            >
              {link.page}
            </Typography>
          ))}
        </Links>
        <MenuBox onClick={(e) => setOpen(true)}>
          <MenuIcon />
        </MenuBox>
      </StyledToolbar>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        open={open}
        onClose={(e) => setOpen(false)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {links.map((link) => (
          // eslint-disable-next-line react/jsx-key
          <MenuItem>
            <Typography
              variant="h6"
              noWrap
              component="a"
              href={link.href}
              sx={{
                color: '#435170',
                fontWeight: 'normal !important',
                fontSize: '17px !important',
              }}
            >
              {link.page}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </AppBar>
  );
};

export default Navbar;

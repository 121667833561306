// firebase
import {
  addDoc,
  collection,
  doc,
  serverTimestamp,
  setDoc,
  query,
  where,
  getDocs,
  updateDoc,
  getDoc,
} from 'firebase/firestore';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { Grid, TextField, Button, Card, CardContent, Typography, MenuItem, Avatar } from '@mui/material';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { auth, db, storage } from '../../../firebase';
import { useAuth } from '../../../context/AuthContext';
import { v4 as uuidv4 } from 'uuid';
import Iconify from '../../../components/iconify';

const exchanges = [
  {
    value: 'Binance',
    label: 'Binance',
  },
  {
    value: 'Bybit',
    label: 'Bybit',
  },
  {
    value: 'Angelone',
    label: 'Angelone',
  },
];

function AddExchangePageForm(props) {
  const navigate = useNavigate();
  const [exchangeAvatar, setExchangeAvatar] = useState("");
  const { signup, currentUser } = useAuth();
  const paperStyle = { padding: '0 15px 40px 15px', width: 250 };
  const btnStyle = { marginTop: 10 };
  const fieldStyle = { marginTop: 10 };
  const phoneRegExp = /^[2-9]{2}[0-9]{8}/;
  const passwordRegExp = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
  const initialValues = {
    exchange: '',
    name: '',
    apikey: '',
    apisecret: '',
    clientid: '',
    password: '',
    totp: ''
  };
  const validationSchema = Yup.object().shape({
    exchange: Yup.string().required('Required'),
    name: Yup.string().required('Required'),
    apikey: Yup.string().required('Required'),
    apisecret: Yup.string().required('Required'),
    /*
    exchange: Yup.string().min(3, "It's too short").required('Required'),
    email: Yup.string().email('Enter valid email').required('Required'),
    // phoneNumber: Yup.number().typeError("Enter valid Phone number").required("Required"),
    phoneNumber: Yup.string().matches(phoneRegExp, 'Enter valid Phone number').required('Required'),
    password: Yup.string()
      .min(8, 'Minimum characters should be 8')
      .matches(passwordRegExp, 'Password must have one upper, lower case, number, special symbol')
      .required('Required'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password')], 'Password not matches')
      .required('Required'),
      */
  });
  const onSubmit = async (values, props) => {
    try {
      const docRef = doc(db, 'Customers', currentUser.uid);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        // console.log("Document data:", docSnap.data());
        const aExchanges = docSnap.data().exchanges;
        let oExchange = {}
        if (values.exchange === "Angelone") {
          oExchange = {
            name: values.name,
            exchangeId: uuidv4(),
            exchangeName: values.exchange,
            apikey: values.apikey,
            apisecret: values.apisecret,
            clientid: values.clientid,
            password: values.password,
            totp: values.totp
          }
        } else {
          oExchange = {
            name: values.name,
            exchangeId: uuidv4(),
            exchangeName: values.exchange,
            apikey: values.apikey,
            apisecret: values.apisecret,
          }
        }
        aExchanges.push(oExchange);
        await updateDoc(docRef, {
          exchanges: aExchanges,
        });
        alert('Exchange/Broker linked Successfull');
        props.resetForm();
        navigate('/dashboard/exchanges');
      } else {
        // doc.data() will be undefined in this case
        alert('Error - Exchange/Broker linking failed');
        props.resetForm();
      }
    } catch (error) {
      console.log(error);
      alert('Unexpected error. Please contact support');
    }
  };

  return (
    <div className="App">
      <Grid>
        <Card style={{ maxWidth: 450, padding: '20px 5px', margin: '0 auto' }}>
          <CardContent>
            <Typography variant="body2" color="textSecondary" component="p" gutterBottom>
              Fill up the form and submit to link your exchange/broker with BuildMyStock.com.
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p" gutterBottom>
              Important : Ensure api keys does not have cash withdraw rights.
            </Typography>
            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
              {(props) => (
                <Form noValidate>
                  <Grid container spacing={1}>
                    <Grid item xs={8}>
                      <Field
                        as={TextField}
                        id="outlined-select-exchange"
                        select
                        label="Exchange/Broker"
                        defaultValue="Binance"
                        helperText="Please select your exchange"
                        required
                        value={props.values.exchange}
                        style={fieldStyle}
                        name="exchange"
                        error={props.errors.exchange && props.touched.exchange}
                      >
                        {exchanges.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Field>
                    </Grid>
                    <Grid item xs={4} sx={{ mt: 1 }}>
                      <Avatar
                        alt="exchange icon"
                        src={(
                          props.values.exchange === "Binance" ? `/assets/images/avatars_exchanges/binance.svg` : props.values.exchange === "Bybit" ?
                            `/assets/images/avatars_exchanges/bybit.svg` : props.values.exchange === "Angelone" ? `/assets/images/avatars_exchanges/angelone.png` : ``
                        )}
                        sx={{
                          width: 56, height: 56,
                          display: props.values.exchange === "" ? "none" : "inherit"
                        }}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Field
                        as={TextField}
                        name="name"
                        label="Name"
                        type="text"
                        fullWidth
                        required
                        error={props.errors.name && props.touched.name}
                        // helperText={<ErrorMessage name="name" />}
                        helperText= "Please use _ for space eg: exchange_test_1"
                        // required
                        style={fieldStyle}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        as={TextField}
                        name="apikey"
                        label="API Key"
                        type="password"
                        fullWidth
                        error={props.errors.apikey && props.touched.apikey}
                        helperText={<ErrorMessage name="apikey" />}
                        required
                        style={fieldStyle}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        as={TextField}
                        name="apisecret"
                        label="API Secret"
                        type="password"
                        fullWidth
                        error={props.errors.apisecret && props.touched.apisecret}
                        helperText={<ErrorMessage name="apisecret" />}
                        required
                        style={fieldStyle}
                      />
                    </Grid>
                    {(props.values.exchange === 'Angelone') && (
                      <>
                        <Grid item xs={12}>
                          <Field
                            as={TextField}
                            name="clientid"
                            label="Client ID"
                            type="text"
                            fullWidth
                            error={props.errors.clientid && props.touched.clientid}
                            helperText={<ErrorMessage name="clientid" />}
                            // required
                            style={fieldStyle}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Field
                            as={TextField}
                            name="password"
                            label="Password"
                            type="password"
                            fullWidth
                            error={props.errors.password && props.touched.password}
                            helperText={<ErrorMessage name="password" />}
                            // required
                            style={fieldStyle}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Field
                            as={TextField}
                            name="totp"
                            label="TOTP"
                            type="password"
                            fullWidth
                            error={props.errors.totp && props.touched.totp}
                            helperText={<ErrorMessage name="totp" />}
                            // required
                            style={fieldStyle}
                          />
                        </Grid>
                      </>
                    )}
                    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <Button type="submit" variant="contained" color="primary" startIcon={<Iconify icon="material-symbols:save" />}>
                        Save
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </CardContent>
        </Card>
      </Grid>
    </div>
  );
}

export default AddExchangePageForm;

/* eslint-disable */
import React from 'react';
import "./headerbar.css";
import "./navbar.css";

const Navbar = () => {
  return (
    <nav id="navbar" className="navbar">
    <ul>
      <li><a className="nav-link scrollto active" href="/home">Home</a></li>
      <li><a className="nav-link scrollto" href="/home#services">Services</a></li>
      <li><a className="nav-link scrollto" href="/home#about">About Us</a></li>
      <li><a className="nav-link scrollto" href="/login">Log In</a></li>
      <li><a className="nav-link scrollto" href="/signup">Sign Up</a></li>
   {/*<li><a className="nav-link scrollto" href="#contact">Contact</a></li> */}
      <li><a className="getstarted scrollto" href="/signup">Get Started</a></li>
    </ul>
    <i className="bi bi-list mobile-nav-toggle"></i>
  </nav>
  )
}

export default Navbar
/* eslint-disable */
import React from "react";
import "./hero.css";
import { Link, useNavigate } from "react-router-dom";

const Hero = () => {
  const navigate = useNavigate();
  function onStart(e) {
    e.preventDefault();
    navigate("/signup");
  }
  return (
    <section id="hero">
      <div className="container">
        <div className="row d-flex align-items-center">
          <div className="col-lg-6 py-5 py-lg-0 order-2 order-lg-1 mb-5">
            <h1>Trade the big moves in the crypto market</h1>
            <h2>
              You can now connect with any of your crypto exchanges account and do smart trading
              securely and seamlessly
            </h2>

            <div className="input-group mb-3 input-group-lg w-75">
              <div className="input-group-append">
              <button onClick={onStart} className="btn btn-lg button" type="submit">
                <span> Get Started </span>
                </button>
              </div>
            </div>

            {/*  <a href="/login" className="btn-get-started scrollto">Get Started</a>. */}
          </div>

          <div className="col-lg-6 order-1 order-lg-2 hero-img">
            <img src="assets/images/bitCoin.svg" className="img-fluid" alt="" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;

/*

            <div class="input-group mb-3 input-group-lg w-75">
              <input type="text" class="form-control" placeholder="Enter your email address" />
              <div class="input-group-append">
                <button onClick={onStart} class="btn custom-btn-class btn-lg" type="submit">
                  Get Started
                </button>
              </div>
            </div>

*/

// firebase
import {
  addDoc,
  collection,
  doc,
  serverTimestamp,
  setDoc,
  query, 
  where, 
  getDocs,
  updateDoc,
  getDoc
} from "firebase/firestore";
import React from 'react';
import { Grid, TextField, Button, Card, CardContent, Typography, MenuItem } from '@mui/material';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { auth, db, storage } from "../../../firebase";
import { useAuth } from "../../../context/AuthContext";

const exchanges = [
  {
    value: 'Binance',
    label: 'Binance',
  },
  {
    value: 'Bybit',
    label: 'Bybit',
  },
];

const tradetypes = [
  {
    value: 'Futures',
    label: 'Futures',
  },
  {
    value: 'Spot',
    label: 'Spot',
  },
];

const algo = [
  {
    value: 'StatFuturesSwingSmall',
    label: 'BMT extended futures swing small lot - 10USDT',
  },
  {
    value: 'StatFuturesSwingMedium',
    label: 'BMT extended futures swing medium lot - 50USDT',
  },
  {
    value: 'StatFuturesShortTermMedium',
    label: 'BMT extended futures short term medium lot - 50USDT',
  },
  {
    value: 'StatFuturesSwingLarge',
    label: 'BMT extended futures swing large lot - 500USDT',
  },
  {
    value: 'StatSpotSwingSmall',
    label: 'BMT extended SPOT swing small lot - 100USDT',
  },
  {
    value: 'StatSpotSwingMedium',
    label: 'BMT extended SPOT swing medium lot - 200EUR',
  }
];

const coin = [
  {
    value: 'BTCUSDT',
    label: 'BTCUSDT',
  },
  {
    value: 'ETHUSDT',
    label: 'ETHUSDT',
  },
  {
    value: 'BTCBUSD',
    label: 'BTCBUSD',
  },
  {
    value: 'ETHBUSD',
    label: 'ETHBUSD',
  },
  {
    value: 'BTCEUR',
    label: 'BTCEUR',
  },
  {
    value: 'ETHEUR',
    label: 'ETHEUR',
  },
  {
    value: 'ALPHAUSDT',
    label: 'ALPHAUSDT',
  },
  {
    value: 'XRPBUSD',
    label: 'XRPBUSD',
  },
  {
    value: 'ADAUSDT',
    label: 'ADAUSDT',
  }
];

function CreateStrategyPageForm() {
  const { signup, currentUser } = useAuth();
  const paperStyle = { padding: '0 15px 40px 15px', width: 250 };
  const btnStyle = { marginTop: 10 };
  const fieldStyle = { marginTop: 10 };
  const phoneRegExp = /^[2-9]{2}[0-9]{8}/;
  const passwordRegExp = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
  const initialValues = {
    exchange: '',
    tradetype: '',
    algo: '',
    coin:''
  };
  const validationSchema = Yup.object().shape({
    exchange: Yup.string().required('Required'),
    tradetype: Yup.string().required('Required'),
    algo: Yup.string().required('Required'),
    coin: Yup.string().required('Required'),
    /*
    exchange: Yup.string().min(3, "It's too short").required('Required'),
    email: Yup.string().email('Enter valid email').required('Required'),
    // phoneNumber: Yup.number().typeError("Enter valid Phone number").required("Required"),
    phoneNumber: Yup.string().matches(phoneRegExp, 'Enter valid Phone number').required('Required'),
    password: Yup.string()
      .min(8, 'Minimum characters should be 8')
      .matches(passwordRegExp, 'Password must have one upper, lower case, number, special symbol')
      .required('Required'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password')], 'Password not matches')
      .required('Required'),
      */
  });
   const  onSubmit = async (values, props) => {
    // console.log(values)
    // alert(JSON.stringify(values), null, 2);
    try {
      const docRef = doc(db, 'Customers', currentUser.uid);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists() && docSnap.data().exchanges.length !== 0) {
        let oExchange;
        let sStatId = "";
        let sCollectionName = "";
        const aExchanges = docSnap.data().exchanges;
        // eslint-disable-next-line no-plusplus
        for(let i=0; i<aExchanges.length; i++) {
          if(aExchanges[i].exchangeName === values.exchange ){
              oExchange = {...aExchanges[i]};
          }
        }
        sStatId = docSnap.id + values.exchange + values.coin;
        sCollectionName = values.algo + values.exchange + values.coin;
        await setDoc(doc(db, sCollectionName, sStatId), {
          exchange: oExchange,
          algo: values.algo,
          coin: values.coin,
          status: "Play",
          timeStamp: serverTimestamp(),
        });
      alert("Successfull");
      props.resetForm();
      } else {
        // doc.data() will be undefined in this case
        alert('No exchanges linked please add your exchange');
        props.resetForm();
      }
    }catch(error){
      console.log(error)
      alert("Failed. Please contact support")
    }  
  };
  return (
    <div className="App">
      <Grid>
        <Card style={{ maxWidth: 450, padding: '20px 5px', margin: '0 auto' }}>
          <CardContent>
            <Typography variant="body2" color="textSecondary" component="p" gutterBottom>
            Before creating strategy please make sure to add your crypto exchange to BuildMyStock
            </Typography>
            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
              {(props) => (
                <Form noValidate>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Field
                        as={TextField}
                        id="outlined-select-exchange"
                        select
                        label="Exchange"
                        defaultValue="Binance"
                        helperText="Please select your linked exchange"
                        required
                        value={props.values.exchange}
                        style={fieldStyle}
                        name="exchange"
                        error={props.errors.exchange&&props.touched.exchange}
                      >
                        {exchanges.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Field>
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        as={TextField}
                        id="outlined-select-tradetype"
                        select
                        label="Trade Type"
                        helperText={<ErrorMessage name="tradetype" />}
                        required
                        value={props.values.tradetype}
                        style={fieldStyle}
                        name="tradetype"
                        error={props.errors.tradetype&&props.touched.tradetype}
                        fullWidth
                      >
                        {tradetypes.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Field>
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        as={TextField}
                        id="outlined-select-algo"
                        select
                        label="Algo"
                        helperText={<ErrorMessage name="algo" />}
                        required
                        value={props.values.algo}
                        style={fieldStyle}
                        name="algo"
                        error={props.errors.algo&&props.touched.algo}
                        fullWidth
                      >
                        {algo.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Field>
                    </Grid>  
                    <Grid item xs={12}>
                      <Field
                        as={TextField}
                        id="outlined-select-coin"
                        select
                        label="Coin"
                        helperText={<ErrorMessage name="coin" />}
                        required
                        value={props.values.coin}
                        style={fieldStyle}
                        name="coin"
                        error={props.errors.coin&&props.touched.coin}
                        fullWidth
                      >
                        {coin.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Field>
                    </Grid>                    
                    <Grid item xs={12}>
                      <Button type="submit" variant="contained" color="primary" >
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </CardContent>
        </Card>
      </Grid>
    </div>
  );
}

export default CreateStrategyPageForm;

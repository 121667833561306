import { faker } from '@faker-js/faker';
import { sample } from 'lodash';

// ----------------------------------------------------------------------
/*
const users = [...Array(1)].map((_, index) => ({
  id: faker.datatype.uuid(),
//  avatarUrl: `/assets/images/avatars/avatar_${index + 1}.jpg`,
 // name: faker.name.fullName(),
  avatarUrl: `/assets/images/avatars_exchanges/binance-logo.svg`,
  name:"Binance",
  company: faker.company.name(),
  isVerified: faker.datatype.boolean(),
  status: sample(['active', 'banned']),
  role: sample([
    'Leader',
    'Hr Manager',
    'UI Designer',
    'UX Designer',
    'UI/UX Designer',
    'Project Manager',
    'Backend Developer',
    'Full Stack Designer',
    'Front End Developer',
    'Full Stack Developer',
  ]),
}));
*/

const users = [];
export default users;

/* eslint-disable */
import React from "react";
import "./services.css";

const Services = () => {
  return (
    <section id="services" className="services section-bg">
      <div className="container">
        <div className="section-title">
          <h2>Services</h2>
          <p>All our services are free to use for all members till end of 2023</p>
        </div>
        <div className="row">
          <div className="col-md-6 d-flex">
            <div className="card">
              <div className="card-img">
                <img src="assets/images/features.svg" alt="..." />
              </div>
            </div>
          </div>
          <div className="col-md-6 d-flex">
          <div className="card-body features">
                <h5 className="card-title"><a className="pointer-diabled"  href="">Smart Crypto Trading</a></h5>
                <div className="read-more"><a className="pointer-diabled" href=""><i className="bi bi-arrow-right"></i> Integration with Multi Crypto Exchanges</a></div>
                <div className="read-more"><a className="pointer-diabled" href=""><i className="bi bi-arrow-right"></i> Crypto Indicators to detect Market Moves</a></div>
                <div className="read-more"><a className="pointer-diabled" href=""><i className="bi bi-arrow-right"></i> Fully Or Semi-Automated with customized strategies</a></div>
                <div className="read-more"><a className="pointer-diabled" href=""><i className="bi bi-arrow-right"></i> Create trade circle for your family</a></div>
                <div className="read-more"><a className="pointer-diabled" href=""><i className="bi bi-arrow-right"></i> Analytical dashboard to view trade KPI's</a></div>
              </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;

/*
          <div className="col-md-6 d-flex" >
            <div className="card">
              <div className="card-img">
                <img src="assets/img/services-1.jpg" alt="..."/>
              </div>
              <div className="card-body">
                <h5 className="card-title"><a href="">Build your brand</a></h5>
                <p className="card-text">Create a business name and logo</p>
                <div className="read-more"><a href="#"><i className="bi bi-arrow-right"></i> Read More</a></div>
              </div>
            </div>
          </div>
          <div className="col-md-6 d-flex">
            <div className="card">
              <div className="card-img">
                <img src="assets/img/services-2.jpg" alt="..."/>
              </div>
              <div className="card-body">
                <h5 className="card-title"><a href="">Build your online presence</a></h5>
                <p className="card-text">Put your brand into action—develop customer loyalty and promote your business on social</p>
                <div className="read-more"><a href="#"><i className="bi bi-arrow-right"></i> Read More</a></div>
              </div>
            </div>

          </div>
          <div className="col-md-6 d-flex">
            <div className="card">
              <div className="card-img">
                <img src="assets/img/services-3.jpg" alt="..."/>
              </div>
              <div className="card-body">
                <h5 className="card-title"><a href="">Build your platform</a></h5>
                <p className="card-text">Use DMC’s powerful features to start managing your trading circles</p>
                <div className="read-more"><a href="#"><i className="bi bi-arrow-right"></i> Read More</a></div>
              </div>
            </div>

          </div>

*/

// firebase
import {
  addDoc,
  collection,
  doc,
  serverTimestamp,
  setDoc,
  query,
  where,
  getDocs,
  updateDoc,
  getDoc
} from "firebase/firestore";
import React from 'react';
import {
  Grid, TextField, Button, Card, CardContent, Typography, MenuItem, FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Switch,
  ListItemIcon
} from '@mui/material';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import Iconify from '../../../components/iconify';
import * as Yup from 'yup';
import { auth, db, storage } from "../../../firebase";
import { useAuth } from "../../../context/AuthContext";
import { useEffect, useState } from 'react';
import { arEG } from "date-fns/locale";

const EngineTypes = [
  {
    value: 'SST',
    label: 'Static strategy based trading',
  },
  {
    value: 'AT',
    label: 'Algo based trading',
  },
];


const engineIcons = [
  { value: 'e1', label: 'e1', icon: "/assets/engine_icons/e1.png" },
  { value: 'e2', label: 'e2', icon: "/assets/engine_icons/e2.png" },
  { value: 'e3', label: 'e3', icon: "/assets/engine_icons/e3.png" },
  { value: 'e4', label: 'e4', icon: "/assets/engine_icons/e4.png" },
  { value: 'e5', label: 'e5', icon: "/assets/engine_icons/e5.png" },
  { value: 'e6', label: 'e6', icon: "/assets/engine_icons/e6.png" },
  { value: 'e7', label: 'e7', icon: "/assets/engine_icons/e7.png" },
  { value: 'e8', label: 'e8', icon: "/assets/engine_icons/e8.png" },
  { value: 'e9', label: 'e9', icon: "/assets/engine_icons/e9.png" },
  { value: 'e10', label: 'e10', icon: "/assets/engine_icons/e10.png" },
];


function CreateTradeEngineForm() {

  const { signup, currentUser } = useAuth();
  const [myAlgos, setMyAlgos] = useState([]);
  const [myStrategies, setMyStrategies] = useState([]);
  const [myExchanges, setMyExchanges] = useState([]);

  const paperStyle = { padding: '0 15px 40px 15px', width: 250 };
  const btnStyle = { marginTop: 10 };
  const fieldStyle = { marginTop: 10 };
  const phoneRegExp = /^[2-9]{2}[0-9]{8}/;
  const passwordRegExp = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
  const initialValues = {
    tradeEngineName: '',
    engineIcon: "/assets/engine_icons/e1.png",
    engineType: '',
    algo: '',
    strategyCE: '',
    strategyPE: '',
    enablePaperTrade: true,
    exchange: '',
  };
  const transactionOptions = ['Buy', 'Sell'];
  const orderOptions = ['Market', 'Limit'];
  const optionTypes = ['CE', 'PE', 'Both'];
  const booleanTypes = ['Yes', 'No']
  const validationSchema = Yup.object().shape({
    // exchange: Yup.string().required('Required'),
    // tradetype: Yup.string().required('Required'),
    // algo: Yup.string().required('Required'),
    // coin: Yup.string().required('Required'),
    /*
    exchange: Yup.string().min(3, "It's too short").required('Required'),
    email: Yup.string().email('Enter valid email').required('Required'),
    // phoneNumber: Yup.number().typeError("Enter valid Phone number").required("Required"),
    phoneNumber: Yup.string().matches(phoneRegExp, 'Enter valid Phone number').required('Required'),
    password: Yup.string()
      .min(8, 'Minimum characters should be 8')
      .matches(passwordRegExp, 'Password must have one upper, lower case, number, special symbol')
      .required('Required'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password')], 'Password not matches')
      .required('Required'),
      */
  });


  const onSubmit = async (values, props) => {
    // console.log(values)
    // alert(JSON.stringify(values), null, 2);
    try {
      if(!values.strategyCE && !values.algo && !values.strategyPE){
        alert("No Strategy or Algo selected atleast one is needed for creation Trade Engine")
        return
      }
      const docRef = doc(db, 'Customers', currentUser.uid);
      const docSnap = await getDoc(docRef);
      const aBrokers = []
      const selectedExchange = myExchanges.find((item) => item.value === values.exchange);
      const oBroker = {
        customer : currentUser.uid,
        exchangeName : selectedExchange.exchangeName,
        exchangeId : values.exchange
      }
      aBrokers.push(oBroker)

      const statRefCE =  values.strategyCE ? doc(db, 'Strategies', values.strategyCE) : null;
      const statSnapCE = values.strategyCE ? await getDoc(statRefCE) :  null;

      const statRefPE =  values.strategyPE ? doc(db, 'Strategies', values.strategyPE) : null;
      const statSnapPE = values.strategyPE ? await getDoc(statRefPE) :  null;

      const algoRef = values.algo ? doc(db, 'Algos', values.algo) : null;
      const algoSnap =  values.algo ? await getDoc(algoRef) :  null;

      const oRefStatCE =  values.strategyCE ? statSnapCE.data() : {}
      const oRefStatPE =  values.strategyPE ? statSnapPE.data() : {}
      const oRefAlgo = values.algo ? algoSnap.data() : {}

      if (docSnap.exists()) {
        let sCollectionName = "";
        sCollectionName = "TradeEngines"
        let responseStat = await addDoc(collection(db, sCollectionName), {
          tradeEngineName: values.tradeEngineName,
          engineIcon: values.engineIcon,
          engineType: values.engineType,
          algoId: values.algo,
          strategyCEId: values.strategyCE,
          strategyPEId: values.strategyPE,
          enablePaperTrade: values.enablePaperTrade,
          MasterCustomer: currentUser.uid,
          MasterExchangeName : selectedExchange.exchangeName,
          MasterExchangeId : values.exchange,
          active: true,
          timeStamp: serverTimestamp(),
          refStatCE: oRefStatCE,
          refStatPE: oRefStatPE,
          refAlgo: oRefAlgo,
          refExchanges:[oBroker]
        });
        let aAlgos = docSnap.data().tradeEngines;
        if (aAlgos) {
          aAlgos.push({
            name: values.tradeEngineName,
            id: responseStat.id
          })
        } else {
          aAlgos = []
          aAlgos.push({
            name: values.tradeEngineName,
            id: responseStat.id
          })
        }
        await updateDoc(docRef, {
          tradeEngines: aAlgos,
        });
        if (values.algo) {
          let aAlgoEngines =  algoSnap.data().tradeEngines;
          if (aAlgoEngines) {
            aAlgoEngines.push({
              name: values.tradeEngineName,
              id: responseStat.id
            })
          } else {
            aAlgoEngines = []
            aAlgoEngines.push({
              name: values.tradeEngineName,
              id: responseStat.id
            })
          }
          await updateDoc(algoRef, {
            tradeEngines: aAlgoEngines,
          });
        }

        console.log(responseStat);
        console.log("Document written with ID: ", responseStat.id);
        alert("Successfull" + responseStat.id);
        props.resetForm();
      } else {
        // doc.data() will be undefined in this case
        alert('No exchanges linked please add your exchange');
        props.resetForm();
      }
    } catch (error) {
      console.log(error)
      alert("Failed. Please contact support")
    }
  };

  useEffect(() => {
    // Fetch data from the 'orders' collection
    const fetchData = async () => {

      const docRef = doc(db, 'Customers', currentUser.uid);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        let aAlgos = []
        let aStat = []
        let aExchanges = []
        for (let i = 0; i < docSnap.data().strategies.length; i++) {
          let oStat = {}
          oStat.value = docSnap.data().strategies[i].id
          oStat.label = docSnap.data().strategies[i].name
          aStat.push(oStat)
        }
        for (let i = 0; i < docSnap.data().algos.length; i++) {
          let oAlgo = {}
          oAlgo.value = docSnap.data().algos[i].id
          oAlgo.label = docSnap.data().algos[i].name
          aAlgos.push(oAlgo)
        }
        for (let i = 0; i < docSnap.data().exchanges.length; i++) {
          let oExchange = {}
          oExchange.value = docSnap.data().exchanges[i].exchangeId
          oExchange.label = docSnap.data().exchanges[i].name
          oExchange.exchangeName = docSnap.data().exchanges[i].exchangeName
          aExchanges.push(oExchange)
        }
        setMyAlgos(aAlgos)
        setMyStrategies(aStat)
        setMyExchanges(aExchanges)
      }

    };

    fetchData();
  }, []);
  return (
    <div className="App">
      <Grid>
        <Card style={{ maxWidth: 750, padding: '20px 5px', margin: '0 auto' }}>
          <CardContent>
            <Typography variant="body2" color="textSecondary" component="p" gutterBottom>
              Trade Engine Info
            </Typography>
            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
              {(props) => (
                <Form noValidate>
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={6} lg={6}>
                      <Field
                        as={TextField}
                        name="tradeEngineName"
                        label="Trade Engine Name"
                        type="text"
                        fullWidth
                        error={props.errors.tradeEngineName && props.touched.tradeEngineName}
                        helperText={<ErrorMessage name="tradeEngineName" />}
                        // required
                        style={fieldStyle}
                      />
                    </Grid>
                    <Grid item xs={12} md={2} lg={2}  >
                      <Field
                        as={TextField}
                        fullWidth
                        id="outlined-select-engineIcon"
                        select
                        label="Engine Icon"
                        required
                        value={props.values.engineIcon}
                        style={fieldStyle}
                        name="engineIcon"
                        error={props.errors.engineIcon && props.touched.engineIcon}
                      >
                        {engineIcons.map((option) => (
                          <MenuItem key={option.icon} value={option.icon}>
                            <ListItemIcon>
                              <img src={option.icon} alt={option.label} style={{ width: 34, height: 34 }} />
                            </ListItemIcon>
                          </MenuItem>
                        ))}
                      </Field>
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        as={TextField}
                        id="outlined-select-enginetype"
                        select
                        label="Select Engine Type"
                        required
                        fullWidth
                        style={fieldStyle}
                        name="engineType"
                        error={props.errors.engineType && props.touched.engineType}
                      >
                        {EngineTypes.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Field>
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        as={TextField}
                        id="outlined-select-exchange"
                        select
                        label="Select Exchange"
                        helperText="Please select your exchange linked"
                        // required
                        style={fieldStyle}
                        name="exchange"
                        error={props.errors.exchange && props.touched.exchange}
                      >
                        {myExchanges.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Field>
                    </Grid>
                    {(props.values.engineType === "SST") &&
                      <>
                        <Grid item xs={12}>
                          <Field
                            as={TextField}
                            id="outlined-select-strategy"
                            select
                            label="Select Strategy CE"
                            helperText="Please select your strategy for CE"
                            // required
                            style={fieldStyle}
                            name="strategyCE"
                            error={props.errors.strategyCE && props.touched.strategyCE}
                          >
                            {myStrategies.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </Field>
                        </Grid>

                        <Grid item xs={12}>
                          <Field
                            as={TextField}
                            id="outlined-select-strategy"
                            select
                            label="Select Strategy PE"
                            helperText="Please select your strategy for PE"
                            // required
                            style={fieldStyle}
                            name="strategyPE"
                            error={props.errors.strategyPE && props.touched.strategyPE}
                          >
                            {myStrategies.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </Field>
                        </Grid>
                      </>
                    }
                    {(props.values.engineType === "AT") &&
                      <>
                        <Grid item xs={12}>
                          <Field
                            as={TextField}
                            id="outlined-select-algo"
                            select
                            label="Select Algo"
                            helperText="Please select your algo"
                            // required
                            style={fieldStyle}
                            name="algo"
                            error={props.errors.algo && props.touched.algo}
                          >
                            {myAlgos.map((option) => (
                              <MenuItem key={option.value} value={option.value}
                                defaultChecked="true">
                                {option.label}
                              </MenuItem>
                            ))}
                          </Field>
                        </Grid>

                        <Grid item xs={12}>
                          <Field
                            as={TextField}
                            id="outlined-select-strategy"
                            select
                            label="Select Strategy CE"
                            helperText="Please select your strategy CE"
                            // required
                            style={fieldStyle}
                            name="strategyCE"
                            error={props.errors.strategyCE && props.touched.strategyCE}
                          >
                            {myStrategies.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </Field>
                        </Grid>

                        <Grid item xs={12}>
                          <Field
                            as={TextField}
                            id="outlined-select-strategy"
                            select
                            label="Select Strategy PE"
                            helperText="Please select your strategy PE"
                            // required
                            style={fieldStyle}
                            name="strategyPE"
                            error={props.errors.strategyPE && props.touched.strategyPE}
                          >
                            {myStrategies.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </Field>
                        </Grid>
                      </>
                    }
                    <Grid item xs={12}>
                      <Field name="enablePaperTrade">
                        {({ field, form }) => (
                          <FormControlLabel
                            control={<Switch
                              defaultChecked={props.values.enablePaperTrade}
                              checked={field.value}  // Still controlled by Formik
                              onChange={(event) => {
                                form.setFieldValue(field.name, event.target.checked);
                              }}
                            />}
                            label="Enable Paper Trading"
                          />
                        )}
                      </Field>
                      <ErrorMessage name="enablePaperTrade" component="div" />
                      {props.values.enablePaperTrade &&
                        <>
                          <Typography variant="body2" color="textSecondary" component="p" gutterBottom>
                            Live trading is off
                          </Typography>
                        </>
                      }
                      {!props.values.enablePaperTrade &&
                        <>
                          <Typography variant="body2" color="textSecondary" component="p" gutterBottom>
                            Live trading is On
                          </Typography>
                        </>
                      }
                    </Grid>
                    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <Button type="submit" variant="contained" color="primary" startIcon={<Iconify icon="material-symbols:save" />}>
                        Save
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </CardContent>
        </Card>
      </Grid>
    </div>
  );
}

export default CreateTradeEngineForm;
{/* <Grid item xs={12}>
                      <Field
                        as={TextField}
                        id="outlined-select-tradetype"
                        select
                        label="Trade Type"
                        helperText={<ErrorMessage name="tradetype" />}
                        required
                        value={props.values.tradetype}
                        style={fieldStyle}
                        name="tradetype"
                        error={props.errors.tradetype&&props.touched.tradetype}
                        fullWidth
                      >
                        {tradetypes.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Field>
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        as={TextField}
                        id="outlined-select-algo"
                        select
                        label="Algo"
                        helperText={<ErrorMessage name="algo" />}
                        required
                        value={props.values.algo}
                        style={fieldStyle}
                        name="algo"
                        error={props.errors.algo&&props.touched.algo}
                        fullWidth
                      >
                        {algo.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Field>
                    </Grid>  
                    <Grid item xs={12}>
                      <Field
                        as={TextField}
                        id="outlined-select-coin"
                        select
                        label="Coin"
                        helperText={<ErrorMessage name="coin" />}
                        required
                        value={props.values.coin}
                        style={fieldStyle}
                        name="coin"
                        error={props.errors.coin&&props.touched.coin}
                        fullWidth
                      >
                        {coin.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Field>
                    </Grid>                     */}
import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import BlogPage from './pages/BlogPage';
import UserPage from './pages/UserPage';
import LoginPage from './pages/LoginPage';
import SignUpPage from './pages/SignUpPage';
import Page404 from './pages/Page404';
import ProductsPage from './pages/ProductsPage';
import DashboardAppPage from './pages/DashboardAppPage';
import ExchangesPage from './pages/ExchangesPage';
import AddExchangesPage from './pages/AddExchangesPage';
import Home from './pages/Home';
import CryptoCreateStrategyPage from './pages/CryptoCreateStrategyPage';
import CryptoFuturesPage from './pages/CryptoFuturesPage';
import CryptoSpotPage from './pages/CryptoSpotPage';
import ArticlesPage from './pages/ArticlesPage';
import CreateStrategyPage from './pages/CreateStrategyPage';
import CreateAlgoPage from './pages/CreateAlgoPage';
import CreateTradeEnginePage from './pages/CreateTradeEnginePage';
import MyStrategiesPage from './pages/MyStrategiesPage';
import MyAlgosPage from './pages/MyAlgosPage';
import MyEnginesPage from './pages/MyEnginesPage';
import TradesPage from './pages/TradesPage';
import { useAuth } from "./context/AuthContext";

// ----------------------------------------------------------------------
export default function Router() {
  const { currentUser } = useAuth()
  //console.log(currentUser)
  const routes = useRoutes([
    {
      path: '/dashboard',
      element: currentUser ? <DashboardLayout /> : <Navigate to="/login" />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        // { path: 'app', element: <DashboardAppPage /> },
        // { path: 'test', element: <DashboardAppPage /> },
        { path: 'app', element: <DashboardAppPage /> },
        { path: 'user', element: <UserPage /> },
        { path: 'products', element: <ProductsPage /> },
        { path: 'blog', element: <BlogPage /> },
        { path: 'exchanges', element: <ExchangesPage /> },
        { path: 'addexchange', element: <AddExchangesPage /> },
        // { path: 'showorders', element: <DashboardAppPage /> },
        { path: 'addstrategy', element: <CreateStrategyPage /> },
        { path: 'addalgo', element: <CreateAlgoPage /> },
        { path: 'addtradeengine', element: <CreateTradeEnginePage /> },
        { path: 'mystrategies', element: <MyStrategiesPage /> },
        { path: 'myalgos', element: <MyAlgosPage /> },
        { path: 'myengines', element: <MyEnginesPage /> },
        { path: 'cryptospot', element: <CryptoSpotPage /> },
        { path: 'cryptofutures', element: <CryptoFuturesPage /> },
        { path: 'createstrategy', element: <CryptoCreateStrategyPage /> },
        { path: 'articles', element: <ArticlesPage /> },
        { path: 'mytrades', element: <TradesPage /> }
      ],
    },
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
    {
      path: 'home',
      element: <Home/>,
    },
    {
      path: 'signup',
      element:<SignUpPage/>,
    },
    
  ]);

  return routes;
}

/* eslint-disable */
import React from 'react'
import "./about.css"

const About = () => {
  return (
    <section id="about" className="about section-bg">
    <div className="container">

      <div className="row gy-4">
        <div className="image col-xl-5"></div>
        <div className="col-xl-7">
          <div className="content d-flex flex-column justify-content-center ps-0 ps-xl-4">
            <h3 >About Us</h3>
            <p>
            BuildMyStock is an open trading community which has been built with a vision to do secure and ethical trading socially. We are a team which comprises of people from different backgrounds like professional traders, software developers, designers etc. United for a mission to create an open trading community which helps members to learn and to do crypto trading with the help of smart trading tools
            </p>
            <div className="row gy-4 mt-3">
              <div className="col-md-6 icon-box" >
                <i className="bx bx-support"></i>
                <h4><a href="#">Manage</a></h4>
                <p>Seamlessly manage your crypto portfolios</p>
              </div>
              <div className="col-md-6 icon-box" >
                <i className="bx bx-cube-alt"></i>
                <h4><a href="#">Multi Accounts</a></h4>
                <p>Supports integration with crypto exchanges like Binance, Coinbase, FTX etc</p>
              </div>
              <div className="col-md-6 icon-box" >
                <i className="bx bx-images"></i>
                <h4><a href="#">Insights</a></h4>
                <p>Get early insights to crypto market</p>
              </div>
              <div className="col-md-6 icon-box">
                <i className="bx bx-check-circle"></i>
                <h4><a href="#">Crypto Indicators</a></h4>
                <p>Don't miss any trade opportunity with our custom crypto indicators</p>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </section>
  )
}

export default About